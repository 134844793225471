import React from 'react';
import {
  Heading,
  Header,
  Paragraph,
  ThemeProvider,
  createTheme,
  withStyles,
  Arwes,
  Button,
  Table,
  Image,
  Puffs,
  Project,
  Words,
  Footer,
  Frame,
  Link,
  Appear,
  Blockquote,
  Logo
} from 'arwes';
import '@mdi/js'
import '@mdi/react'
//import '@mdi/font'
import { mdiAbTesting } from '@mdi/js';

const styles = theme => ({
  root: {
    padding: [theme.padding, 0],
    backgroundColor: theme.color.primary.base
  }
});

const App = () => (
  <ThemeProvider theme={createTheme()}>
    <Arwes animate show background= '/img/retro2.jpg' pattern= '/img/glow.png'>
      {anim => (
        <><div style={{ padding: '200px 80px', textAlign: 'center', fontFamily: 'Metron', textTransform: 'uppercase' }}>
          <h1><Heading animate show={anim.entered} style={{ fontFamily: 'Ikarus', textTransform: 'none' }}>Hub of one nerd...</Heading></h1>
          <Image animate resources='/img/minion.jpg' style={{ maxWidth: 150, marginLeft: 'auto', marginRight: 'auto' }}></Image>
          <Paragraph animate show={anim.entered}>tech | photography | trainspotting | urbex | architecture | history and pop-culture of 60s - 80s | art of Eastern Europe | music | aviation | science | cybersecurity | network&sys-administration | web development</Paragraph>
          <Link href="https://www.flickr.com/photos/elecktron/" target="_blank" rel="noopener noreferrer"><Button animate><i className='mdi mdi-camera' /> Flickr</Button></Link>
          {' '}
          <Link href="https://500px.com/p/z_owie" target="_blank" rel="noopener noreferrer"><Button animate><i className='mdi mdi-camera' /> 500px</Button></Link>
          {' '} 
          <Link href="https://open.spotify.com/user/boson-higgs?si=3684f6e5003a41a0" target="_blank" rel="noopener noreferrer"><Button animate layer='success'><i className='mdi mdi-spotify' /> Spotify</Button></Link>
          {' '}
          <Link href="https://github.com/boson-higgs" target="_blank" rel="noopener noreferrer"><Button animate layer='success'><i className='mdi mdi-github' /> GitHub</Button></Link>
          {' '}
          <Link href="https://pinterest.com/RBMKneutron" target="_blank" rel="noopener noreferrer"><Button animate layer='alert'><i className='mdi mdi-pinterest' /> Pinterest</Button></Link>
          {' '}
          <Link href="https://ko-fi.com/lt_columbo" target="_blank" rel="noopener noreferrer"><Button animate layer ='alert'><i className='mdi mdi-coffee' /> Ko-Fi</Button></Link>
          <br></br>
          <br></br>
          <Link href="https://linuxlab.sh/@quark" target="_blank" rel="me noopener noreferrer"><Button animate layer='alert'><i className='mdi mdi-mastodon' /> Mastodon</Button></Link>
          {' '}
          <Link href="https://transphoto.org/author/16592/" target="_blank" rel="noopener noreferrer"><Button animate><i className='mdi mdi-camera' /> Transphoto</Button></Link>
          {' '}
          <Link href="https://busphoto.eu/author/4007/" target="_blank" rel="noopener noreferrer"><Button animate><i className='mdi mdi-camera' /> Busphoto</Button></Link>
          {' '}
          <Link href="https://photobuildings.com/author/1407/" target="_blank" rel="noopener noreferrer"><Button animate><i className='mdi mdi-camera' /> Photobuildings</Button></Link>
        </div>

        <Paragraph style={{ fontFamily: 'Ikarus', textAlign: 'center' }}>Projects of mine</Paragraph>
        
          <Project
            animate show={anim.entered}
            header='Crypto Miner'
            style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', padding: 20, fontFamily: 'Courier' }}>
            {anim => (
              <p>
                <Blockquote>Miner of Duino Coin (DUCO) and Magi Coin (XMG) crypto. Consisted of Raspberry Pi 4 which mines XMG and Arduino Mega 2560 which mines DUCO connected via universal serial bus to Raspberry Pi. Raspberry Pi is monitored by Node Exporter and Prometheus, metrics are visualised in Grafana.</Blockquote>
                <img src="/img/duco.png" alt="Duino Coin" style= {{maxWidth: 355, padding: 5}}></img>
                <img src="/img/magi.png" alt="Magi Coin" style= {{maxWidth: 355, padding: 5}}></img>
                <img src="/img/device.png" alt="Rasperry Pi" style= {{maxWidth: 250, padding: 5}}></img> 
                <img src="/img/grafana.png" alt="Grafana" style= {{maxWidth: 465, padding: 5}}></img>            
                <p><i className='mdi mdi-calendar-clock' />6/MMXXIII</p>
              </p>
            )}
          </Project>
          <Project
            animate show={anim.entered}
            header='RetroLab'
            style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', padding: 20, fontFamily: 'Courier' }}>
            {anim => (
              <p>
                <Blockquote>RetroLab is a Discord server dedicated to pop-culture of 20th century.
                You can join the server by this invite: <Link href= "https://discord.gg/qxcENGNtb2" target="_blank" rel="noopener noreferrer">https://discord.gg/qxcENGNtb2</Link></Blockquote>
                <i className='mdi mdi-calendar-clock' />7/MMXX
              </p>
            )}
          </Project>        

          <br></br>

          <Footer style= {{padding: 1}}>
            <p style={{ textAlign: 'center' }}><a href="https://info.flagcounter.com/VV9f"><img src="https://s01.flagcounter.com/map/VV9f/size_t/txt_000000/border_CCCCCC/pageviews_1/viewers_0/flags_0/" alt="Flag Counter" border="0"></img></a></p>
            <p style={{ textAlign: 'center', fontFamily: 'Courier' }}> <Link href='https://discordapp.com/users/443959899957755904'>©polargamma.net</Link>   |    Made on <img src="/img/apple.png" alt="Apple" width="17" height="20"></img> device with <Logo animate size={20} /> framework</p>
          </Footer>
          </>
      )}
    </Arwes>
  </ThemeProvider>
);


export default App;

/* <Project
            animate show={anim.entered}
            header='Z.P.G.'
            style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', padding: 20, fontFamily: 'Courier' }}>
            {anim => (
              <p>
                <Blockquote>School project written in C++, using OpenGL. Two scenes with models. First scene with terrain, lights, 4 spheres and house, second scene with terrain, skybox, zombie and Suzi monkey transformating around house. Link to the repository: <Link href= "https://github.com/boson-higgs/Z.P.G." target="_blank" rel="noopener noreferrer">https://github.com/boson-higgs/Z.P.G.</Link></Blockquote>
                <img src= "/img/scene2.png" alt="ZPG"style= {{maxWidth: 700, padding: 5}}></img>
                <p><i className='mdi mdi-calendar-clock' />12/MMXXIII</p>
              </p>
            )}
          </Project> */